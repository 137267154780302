.home{
    margin-top: 150px;
}
.home1 {
    width: 100%;
    height: 55vh;  
} 
.homeCarousel >img{
    object-fit: fill;  
    height: 60vh;   
}
.home1Text1{ 
    font-family: 'FK Grotesk';
    font-style: normal;
    font-weight: 900;
    font-size: 69.6px;
    line-height: 60px; 
    text-transform: uppercase; 
    color: #FB7D33;
}
.home1Text1span{
    font-family: 'FK Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px; 
    text-transform: uppercase; 
    color: #FFFFFF;
}
.home1Text2{
    width: fit-content;
    font-family: 'FK Grotesk';
    font-style: normal;
    background: #F4BD1A;
    font-weight: 500;
    font-size: 26.4px;
    line-height: 32px; 
    color: #090909;
    padding: 0 10px; 
}

 
.home1>div>p{
    margin-left: 5%;
    width: fit-content; 
}

.home2{
    height: 40px;
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: center;
    background-color:rgb(239, 239, 239) ;

}
.whatsNew{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-image: url('../../images/home/whatsNew.png');
    object-fit: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
 
.marquee_new{
    animation: animate .2s linear infinite;  
}
@keyframes animate{
    0%{
      opacity: 0.2;
    }
    50%{
      opacity: 0.8;
    }
    100%{
      opacity: 0;
    }
  }
.home3{
    background:linear-gradient( white 60%, rgb(241, 111, 34) 40%) ;
    margin: 0;
    padding: 0;
}
.home3_0{ 
    width:90%;
    margin: auto;
    display: grid;
    grid-template-columns: 53% 45%;
    gap: 20px;  
    padding: 20px 0;
}
 
.home3_1{
    background-color: white;  
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 19px;
}
.home3_1>p{
font-weight: 500;
font-size: 40px;
line-height: 40px;  
color: #171313;  
}

.home3_1_1{
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 10px; 
    padding: 25px;
}
.home3_1_1>div{  
    display: grid;
    grid-template-rows: 65% 35%;
    padding: 20px ;
    cursor: pointer;
    border: 0.5px solid #999999; 
    border-radius: 4px;
    position: relative;
}
 
.home3_1_1>div>div>p{  
font-weight: 600;
font-size: 16px;
line-height: 18px; 
text-align: center;  
margin:-10px 0;  
}
.home3_1_1>div:hover{
    background-color: #009253; 
    color: #FFFFFF; 
    
}
.home3_1_1>div:hover  .svgColor{
    fill: white;
}
.home3_2{
    display: grid;
    grid-template-columns: 53% 45%;
    gap:20px;
}
.home3_2>div{
    border-radius: 10px;
    padding: 15px; 
}
.home3_2_1{
    background-color: rgb(0, 146, 83);  
}
.home3_2_1>p{
text-align: left; 
font-weight: 700;
font-size: 25px;
line-height: 18px; 
color: #FABF27;
}
.home3_2_1 > button{
    margin:7px;
    width:90% ;
    height: 50px;
    background-color: rgb(0, 146, 83);
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    border: 1px solid; 
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px; 
    color: #F4F4F4;
    
}
.home3_2_1 > button:hover{
    background-color:white;
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    border: 1px solid;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px; 
    color: #009253;
    position: relative;
}
 
.home3_2_2{
    background: #BB355A;
    box-shadow: -7px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: 15px 15px 0px 0px; 
    text-align: left;
    background-image: url('../../images/home/bgOfStors.png');
    object-fit: cover;
    background-size: 80% 50%;
    background-position: right bottom;
    background-repeat: no-repeat;
}
.home3_2_2p1{
    font-weight: 700;
    font-size: 16px;
    line-height: 30px; 
    color: #FFFFFF;
}
.home3_2_2p2{
    font-weight: 400;
    font-size: 20px;
    line-height: 21px; 
    color: #FFFFFF;
}
.home3_2_2>div{
     max-width: 200px; 
    height: 350px;
    margin: auto;

}
.mySlides{
    width: 100%;
    height: 100%;
    margin: auto;
}

.home4{
    /* min-height: 100vh; */
    background:linear-gradient(rgb(241, 111, 34)75%, #ffffff 25%) ;
    padding: 20px;
}
.home4_1{
    width: 90%;
    margin: auto;
}
.home4_1p1{
    font-weight: 500;
    font-size: 50px; 
    text-align: center;
    text-transform: capitalize; 
    color: #FFFFFF;
    width: fit-content;
    margin: auto;
    border-bottom: 3px solid;
}
.home4_1p2{
    width: 97%;
    margin: auto;
    font-weight: 400;
    font-size: 20px; 
    text-align: center; 
    color: #FFFFFF;
}
.home4_2{
    display: grid;
    grid-template-columns: 50% 50% ;
    gap: 20px;
}
.reactPlayer{
    width: 100%;
    height: 400px;
}
.home5{
    background: rgb(234, 234, 234);
    display: grid; 
    gap:10px; 
}

.home5p1{
    font-weight: 500;
    font-size: 50px;
    line-height: 18px; 
    text-align: center;
    text-transform: capitalize; 
    color: #171313;
}
.ourOfferings{
    width: 90%;
    margin: 100px auto;
    display: grid;
    grid-template-columns:  33% 33% 33%;
    gap: 100px 15px; 
    justify-content: space-between;
    align-items: center;
}
.offer{
    height: fit-content;
    cursor: pointer;
    border-radius: 10px; 
    display: grid;
    grid-template-columns: 50% 50%;  
 
}
.offer1> img{
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.offer2{
    padding:0 0 0 10px;
    display: grid;
    text-align: left;
    grid-template-rows: 25% 55% 20%; 
}
 
 
.OurOfferingsBtn{ 
    width: fit-content;
    padding: 8px 15px;
    border: none;
    border-radius: 10px;
    font-weight: 700;
    background-color:rgb(0, 146, 83) ;
    color: white;
}
.home6{
    /* min-height: 80vh; */
    background-image: url('../../images/home/bgOfInterestRates.png'),url('../../images/home/bgOfInterestRates2.png');
    object-fit: cover;
    background-size: 100% 40%;
    background-position: right bottom;
    background-repeat: no-repeat;
}

.interestRate{
    width: 80%;
    margin: auto;
    display: block;
}
.interestRateP{
    font-weight: 400;
    font-size: 70px;
    text-align: center; 
    color: #000000;
}
.interestRateDetails{
    background-color: white;
    border-radius: 15px;
    /* margin: 50px 0; */
    display: flex;
    /* grid-template-columns: 24% 24% 24% 24%; */
    gap: 0px;
    align-items: center;
    justify-content: space-around;
    box-shadow:3px 3px 15px 0px grey;
    padding: 10px ;
}  
.interestRateDetails>div>img{
    width: 40px;
}
 
.exclusive{
width: 85%;
margin: auto;
text-align: left;
}
.exclusiveOffers{
    display: grid;
    grid-template-columns: 33% 66%;
}
.exclusiveOffers1{
    display: grid;
    align-items: center;
    /* padding: 20px; */
    /* background-image: url('../../images/home/exclusiveOffersBg2.png'),url('../../images/home/exclusiveOffersBg.png'); */
    /* object-fit: cover;
    background-size: 100% 100%; 
    background-repeat: no-repeat; */
    min-height: 200px;
}
 
.exclusiveButton{
    width: fit-content;
    padding: 10px 30px;
    border: none;
    border-radius: 10px;
    color: rgb(187, 53, 90);
    font-weight: bold;
}
.exclusiveOffers2{
    display: grid;
    grid-template-columns: 50% 50%; 
    background-color: black;
    gap:1px;
}
 
.exOffers{  
   height: 300px;
   background-color:rgb(255, 246, 240) ;
}
.exOffers:hover{ 
   cursor: pointer;
    background-color: rgb(255, 226, 207) !important;
}
 
.home8{ 
    width: 100%;
    background-color:rgb(245, 245, 248) ;
}
.notification{
    width: 85%;
    margin: auto;
    text-align: left;
}
.notification1p{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.notification_p1{
    font-weight: 500;
    font-size: 40px;
    line-height: 18px; 
    text-transform: capitalize; 
    color: #171313;
    display: flex;
}
.notificationShow{
    margin: 30px 0 0 0;
    display: grid;
    grid-template-columns:50% 50% ; 
    align-items: center;
}
.notificationDiv{
cursor: pointer;  
} 

.rightSlid{ 
    height: 400px;
    display: grid;
    gap: 1px;
    align-items: center;
    justify-content: space-between;
    position: fixed; 
    right: -85px;  
    top: 10%; 
    width: 85px;
    background-color: white;
    transition: 1500ms;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 25px 0px 0px 25px; 
    z-index: 2;
}
.rightSlid:hover{ 
    height: 400px; 
    position: fixed;
    right: 0px;
    top: 10%; 
    width: 85px;
    background-color: white;
    display: grid;
    gap: 1px;
    transition: 1500ms;
    box-shadow: -7px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: 25px 0px 0px 25px;   
    z-index: 2;
}
.rightSlid>div{
    background-color:rgb(187, 53, 90);
    height: 100%; 
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;  
    text-align: center; 
    color: #FFFFFF;
    padding:15px  10px;
    cursor: pointer;
    
}
.rightSlid>div:hover{
    background-color:rgb(182, 37, 78); 
     
}
 
.shoBar{
    border-radius: 15px 0 0 15px;  
    width: 110px;
    margin-left: -20px;
    display: grid;
    grid-template-columns: 5% 95%;
    align-items: center;
    justify-content: center;
} 
#carouselExampleIndicatorsSmall{
    display: none;
}
.carousel .slide{
    padding: 0 !important;
}
.carousel .control-dots{
    display: none;
}
.carousel.carousel-slider .control-arrow{
    height: 50px !important;
    position: absolute !important;
    top: 50% !important; 
}
 
.carousel .carousel-status{
    display: none;
}
#carouselExampleIndicators{
    display: block;
}
.carousel .control-next.control-arrow {
    z-index: 0 !important;
}
@media(max-width:1430px){

}
@media(max-width:1020px){
    .ourOfferings{
        width: 90%;
        margin: 100px auto;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 50px 15px; 
        justify-content: center;
    }
}
@media(max-width:990px){
    .home{
        margin-top: 40px;
    }
    .rightSlid{
        z-index: 0;
    }
    .rightSlid:hover{
        z-index: 0;
    }
}
@media(max-width:800px){ 

    .home1{
        min-height: 75vh;
    }
    #carouselExampleIndicatorsSmall{
        display: block;
    }
    #carouselExampleIndicators{
        display: none;
    }
    .home2{
        height: 40px;
        display: grid;
        grid-template-columns: 40% 60%;
        align-items: center;
    }
   
    .home3_0{
        width: 95%;
        margin: auto;
        display: grid;
        grid-template-columns: 100%;
        gap: 20px ;  
        padding: 10px;
    }
    .home4_1{
        width: 95%;
        margin: auto;
    }
    .home4_2{
        display: grid;
        grid-template-columns:100% ;
        gap: 20px;
    }
    .home5{
        background: rgb(234, 234, 234);
        display: grid;
        grid-template-columns: 100%; 
    }
    /* .ourOfferings{
        width: 90%;
        margin: 50px auto;
        display: grid;
        grid-template-columns:100%;
        gap: 60px 0;
        justify-content: center;
    } */
    .offer{
        max-width: 350px;
        height: 115%;
        cursor: pointer;
        border-radius: 10px; 
        display: grid;
        grid-template-columns: 50% 50%;   
    } 
    .interestRateDetails{
        background-color: white;
        border-radius: 15px;
        margin: 50px 0;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        box-shadow:3px 3px 15px 0px grey;
        padding: 50px ;
    }
    .exclusiveOffers{
        display: grid;
        grid-template-columns: 100%;
    }
    .notificationShow{
        margin:0;
        display: grid;
        grid-template-columns: 50% 50% ;
        gap:30px;
        align-items: center;
    }
}

@media(max-width:590px){
    .home{
        margin-top: 40px; 
    } 
   
 
    #carouselExampleIndicatorsSmall{
        display: block;
    }
    #carouselExampleIndicators{
        display: none;
    } 
    .home3_1_1{
        display: grid;
        grid-template-columns: 49% 49%;
        gap: 10px; 
        padding: 25px;
    } 
    .home3_2{
        display: grid;
        grid-template-columns: 100%;
        gap:20px;
    } 
    .interestRateP{
        font-weight: 400;
        font-size: 45px;
        text-align: center; 
        color: #000000;
    }
    
    .interestRateDetails{
        width: 70%;
        background-color: white;
        border-radius: 15px;
        margin: 50px auto;
        display: grid;
        grid-template-columns: 100%;
        gap: 15px;
        align-items: center;
        justify-content: space-between;
        box-shadow:3px 3px 15px 0px grey;
        padding: 20px ;
    }
        .ourOfferings{
        width: 90%;
        margin: 50px auto;
        display: grid;
        grid-template-columns:100%;
        gap: 60px 0;
        justify-content: center;
    }
    .exclusiveOffers2{
        display: grid;
        grid-template-columns: 100%; 
        background-color: black;
        gap:1px;
    }
    .notificationShow{
        margin:0;
        display: grid;
        grid-template-columns:100% ;
        gap:30px;
        align-items: center;
    }
    .notification_p1{
        font-weight: 500;
        font-size: 40px;
        line-height: 18px; 
        text-transform: capitalize; 
        color: #171313;
        display: flex;
    }
    .notification1p{
        display: grid;
        align-items: center; 
    }
}

/* .home{
    margin-top: 160px;
}
.home1 {
    width: 100%;
    min-height: 70vh;
    background-image: url('../../images/home/banner1.png');
    object-fit: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.home2{
    height: 60px;
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: center;
    background-color:rgb(239, 239, 239) ;

}
.whatsNew{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-image: url('../../images/home/whatsNew.png');
    object-fit: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
 
.home3{
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    min-height: 80vh;
    background:linear-gradient( white 60%, rgb(241, 111, 34) 40%) ;
    padding: 40px;
}
 
.home3_1{
    background-color: white;  
    border-radius: 10px;
    box-shadow:3px 3px 15px 0px grey;
}

.home3_1_1{
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 10px; 
    padding: 25px;
}
.home3_1_1>div{ 
    border: 1px solid;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
}
.home3_1_1>div:hover{
    background-color: #009253;
    color: white;
}
.home3_2{
    display: grid;
    grid-template-columns: 50% 50%;
    gap:20px;
}
.home3_2>div{
    border-radius: 10px;
    padding: 15px;

}
.home3_2_1{
    background-color: rgb(0, 146, 83);
    text-align: left;
    display: grid;
    gap: 20px;
    align-items: center;
    justify-content: left;
}
.home3_2_1 > button{
     width: 110%;
    background-color: rgb(0, 146, 83);
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    border: 1px solid;
    color: white;
    font-weight: bold;
    position: relative;
    
}
.home3_2_1 > button:hover{
    background-color:white;
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    border: 1px solid;
    color: rgb(0, 146, 83); 
    font-weight: bold;
    position: relative;
}
 
.home3_2_2{
    background-color:rgb(204, 104, 131) ;
    text-align: left;
    background-image: url('../../images/home/bgOfStors.png');
    object-fit: cover;
    background-size: 70% 50%;
    background-position: right bottom;
    background-repeat: no-repeat;
}
.home3_2_2>div{
    width: 80%;
    margin: auto;
}
.home3_2_2>div>img{
    width: 100%;
}

.home4{
    min-height: 100vh;
    background:linear-gradient(rgb(241, 111, 34) 60%, rgb(234, 234, 234) 40%) ;
    padding: 20px;
}
.home4_1{
    width: 90%;
    margin: auto;
}
.home4_2{
    display: grid;
    grid-template-columns: 50% 50% ;
    gap: 20px;
}
.reactPlayer{
    width: 100%;
    height: 320px;
}
.home5{
    background: rgb(234, 234, 234);
    display: grid;
    grid-template-columns: 65% 35%;
    gap:10px;
    min-height: 90vh;
}
.ourOfferings{
    width: 90%;
    margin: 50px auto;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 15px;
}
.offer{
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    display: grid;
    text-align: left;
    grid-template-rows: 50% 30% 20%; 
}
.ourOfferings2{
    display: grid;
    grid-template-rows: 50% 50%;
} 
.ourOfferings2 >div >img{
    width: 100%;
    height: 100%;
}
.OurOfferingsBtn{
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-weight: 700;
    background-color:rgb(0, 146, 83) ;
    color: white;
}
.home6{
    min-height: 90vh;
    background-image: url('../../images/home/bgOfInterestRates.png'),url('../../images/home/bgOfInterestRates2.png');
    object-fit: cover;
    background-size: 100% 50%;
    background-position: right bottom;
    background-repeat: no-repeat;
}
.interestRate{
    width: 80%;
    margin: auto;
    display: block;
}
.interestRateDetails{
    background-color: white;
    border-radius: 15px;
    margin: 50px 0;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    box-shadow:3px 3px 15px 0px grey;
    padding: 50px ;
}
.exclusive{
width: 85%;
margin: auto;
text-align: left;
}
.exclusiveOffers{
    display: grid;
    grid-template-columns: 33% 66%;
}
.exclusiveOffers1{
    display: grid;
    align-items: center;
    padding: 20px;
    background-image: url('../../images/home/exclusiveOffersBg2.png'),url('../../images/home/exclusiveOffersBg.png');
    object-fit: cover;
    background-size: 100% 100%; 
    background-repeat: no-repeat;
    min-height: 300px;
}
.exclusiveButton{
    width: fit-content;
    padding: 10px 30px;
    border: none;
    border-radius: 10px;
    color: rgb(187, 53, 90);
    font-weight: bold;
}
.exclusiveOffers2{
    display: grid;
    grid-template-columns: 50% 50%; 
    background-color: black;
    gap:1px;
}
.exclusiveOffers2 >div{
    padding: 20px 50px;
    background-color: rgb(255, 246, 240);
}
.home8{
    min-height: 95vh;
    width: 100%;
    background-color:rgb(245, 245, 248) ;
    background:linear-gradient(rgb(245, 245, 248)80%, rgb(186, 53, 90) 20%) ;
}
.notification{
    width: 85%;
    margin: auto;
    text-align: left;
}
.notificationShow{
    margin: 50px 0;
    display: grid;
    grid-template-columns:33% 33% 33% ;
    gap:30px;
    align-items: center;
}
.notificationDiv{
cursor: pointer;
border-radius: 10px;
display: grid;
grid-template-columns: 80% 20%;
background-color: white;
}
.notificationDiv>div{
    padding: 10px 20px;
}
 
.notificationDiv:hover{
    background-color: #009253;
    color: white; 
}
.notificationSection{
    background-color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow:3px 3px 15px 0px grey;
}
.homeNoticeUi{
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 20px;
    justify-content: space-between;
}
.homeNoticeUiDiv{
    display: flex;
    gap: 20px;
    cursor: pointer;
}
.homeNoticeUiDiv>img{
    height: 30px;
}
.rightSlid{ 
    height: 370px;
    border-radius: 20px 0 0 20px;
    position: fixed;
    right: 0;
    top: 32%; 
    width: 70px;
    background-color: white;
    display: grid;
    gap: 1px;
    box-shadow:3px 3px 25px 0px grey; 

}
.rightSlid>div{
    background-color:rgb(187, 53, 90); 
    font-size: 10px;
    cursor: pointer;
}
.rightSlid>div>img{
    margin: 8px 0;
    width: 20px;
    height: 20px;
    
} */
