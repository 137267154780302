.slidePage{
    margin-top: 150px; 
}
@media(max-width:1168px){
    .slidePage{ 
        margin-top: 180px;  
    }
}
@media(max-width:990px){
    .slidePage{
        margin-top: 50px;
    }
}