@media screen and (-webkit-min-device-pixel-ratio:0) {  
    input[type='range']::-webkit-slider-thumb { 
      cursor: pointer;  
    }

}
 
 
.customer{
    margin-top: 150px;
} 
 
.customer1{
    width: 100%;
    border-top: 4px solid #FB7D33;
    background: #EDEDED;
    border-radius: 0px 0px 8px 8px;
    display: flex; 
    justify-content: space-around;
    overflow-x: auto !important;
} 
.customer1Button{ 
    border-radius: 0px 0px 8px 8px; 
    font-weight: 500;
    font-size: 16px; 
    width: max-content;
}
.customer1Button:hover{
    background-color: #FB7D33;
    border-radius: 0px 0px 8px 8px;
    color: #EDEDED;
}
.customerRight2_1Div{
    color: #ffffff;
    background-color: #BB355A;
    border-radius: 8px;
}
.customerRight2_1Div>p{
color: #ffffff;
font-weight: 500;
font-size: 18px;
padding: 10px 23px;
}
.customerRight2_1{
    background: #F5F5F5;
    border-radius: 8px;
    width: 80%;
}
 
.customerRight2_1>p{
 color: #BB355A;
 font-weight: 500;
 font-size: 18px;
 padding: 5px 23px;
 margin: 1px;
 cursor: pointer;
}
.customerRight2_1 .dropdown-menu{
    background: #F5F5F5; 
}
.customerRight2_1  li> a{
    font-weight: 500;
    font-size: 18px;
    padding: 5px 23px ;
    margin: 2px 0; 
}
.customerRight2_1Div{
    color: #ffffff;
    background-color: #BB355A;
    border-radius: 8px;
}
.customerRight2_1Div>p{
color: #ffffff;
font-weight: 500;
font-size: 18px;
padding: 10px 23px;
}
 .apexcharts-canvas{
    display: inline-table !important;
 }
 .apexcharts-legend{
    display: contents !important;
 }
@media(max-width:1168px){
    .customer{ 
        margin-top: 180px; 
        overflow: hidden;
    }
}
@media(max-width:990px){
    .customer{ 
        margin-top: 50px; 
        overflow: hidden;
    }
}
@media(max-width:770px){
    .customerRight{ 
        margin-left: 9%;
    }
  }