.userProfile{
    margin-top:200px;
}
.userProfile>div{
    width: 90%;
    margin: auto;
}
 
tbody, td, tfoot, th, thead, tr{
    /* border: none !important; */
    font-size: 20px;
    font-weight: bold;
}

@media(max-width:990px){
    .userProfile{
        margin-top: 100px;
    }
}