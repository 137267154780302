.products{
    overflow-x: hidden;
    margin-top: 160px; 
    background:rgb(245, 245, 248) ;
}
.allProduct{
    background: #ffffff;
}
.productsRight1{
   display: flex;
   gap: 20px;
}
.productsRight2_1{
    background: #F5F5F5;
    border-radius: 8px;
    width: 80%;
}
 
.productsRight2_1>p{
 color: #BB355A;
 font-weight: 500;
 font-size: 18px;
 padding: 5px 23px;
 margin: 1px;
 cursor: pointer;
}
.productsRight2_1 .dropdown-menu{
    background: #F5F5F5; 
}
.productsRight2_1  li> a{
    font-weight: 500;
    font-size: 18px;
    padding: 5px 23px ;
    margin: 2px 0; 
}
.productsRight2_1Div{
    color: #ffffff;
    background-color: #BB355A;
    border-radius: 8px;
}
.productsRight2_1Div>p{
color: #ffffff;
font-weight: 500;
font-size: 18px;
padding: 10px 23px;
}
.products1{
    width: 100%;
    border-top: 4px solid #FB7D33;
    background: #EDEDED;
    border-radius: 0px 0px 8px 8px;
    display: flex; 
    overflow-x: auto !important;
}
.products1Button{ 
    border-radius: 0px 0px 8px 8px; 
    font-weight: 500;
    font-size: 16px; 
    width: max-content;
}
.products1Button:hover{
    background-color: #FB7D33;
    border-radius: 0px 0px 8px 8px;
    color: #EDEDED;
}
::-webkit-scrollbar {
    height: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
 
  .products2>div{
    /* height: 40vh ; */
    width: fit-content;
    margin: 0; padding: 0;
  }
 
.products4_1{
    display: grid;
    grid-template-columns: 31% 31% 31% ;
    gap: 20px;
}
.products4_1>div{ 
    border: 0.5px solid #999999;
    border-radius: 4px;
    padding: 20px 20px;
    text-align: center;
}
.products6_1{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap:15px
}
.products6_1 > div{
    border: 0.5px solid #999999;
    border-radius: 4px;
    padding: 15px;
}
.products8_1{
    border-top: 0.5px solid #C4C4C4;
    border-bottom:0.5px solid #C4C4C4 ;
    padding:10px 0;
}
.faqs{
    display: grid;
    grid-template-columns: 90% 10%; 
}

.qus{
font-weight: 500;
font-size: 20px;
line-height: 24px;
}
.qus>p{
    margin: 0;
    padding: 0;
} 
  
.ans>p{
    width: 90%;
    font-weight: 400;
    font-size: 18px;
    line-height: 162%; 
    color: #969696;
    margin: 0;
    padding: 0;
}
.products9p{
    font-weight: 700;
    font-size: 31px;
    line-height: 32px; 
    color: #BB355A;
}
.tableDive{
    overflow-x: auto;
}
/* table, th, td {
    border: 1px solid #A1A1A1;
    border-collapse: collapse;
  }
   */
   
   table, th,td {
    /* border: 1px solid #A1A1A1; */
    font-size: 16px !important;
    border-collapse: collapse;
    text-align: left; 
  }
  tbody,td{
    font-weight: normal !important;
  }
  .table>thead{
    background-color: #EDEDEE !important;
    font-size: 12px;
  }
.overOfferingCursorDiv{
    display: flex;
   
}
#productCarouselButton{
    position: absolute;
    top: 50%;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: rgb(187, 53, 90);
}
.offrings{
    width: 90% ;
    margin: auto;
}
.react-multiple-carousel__arrow--right{
    right: 0;
}
.react-multiple-carousel__arrow--left{
    left: 0;
}
.offringBox{
    height: 180px;
    margin-left: 20%;
}
.gallery >div >div{
    gap: 5px !important;
}
.gallery >div >div>div{
gap: 5px !important;
}
@media(max-width:1245px){
    .products{
        margin-top: 190px; 
    }
    .products6_1{
        display: grid;
        grid-template-columns: 50% 50%;
        gap:15px; 
    }
}
@media(max-width:990px){
    .products{
        margin-top: 50px;
    }
}
@media(max-width:770px){
    .productsRight{
         margin-left: 9%;
    }
    .products1{
        width: 116%;
        margin: 0 -36px;
    }
}
@media(max-width:590px){
    .products{
        margin-top: 50px; 
    }
    .products1{
        width: 125%;
        margin: 0 -37px;
    }
    .products4_1{
        display: grid;
        grid-template-columns: 49% 49% ;
        gap: 15px;
    }
    .products6_1{
        display: grid;
        grid-template-columns: 90%;
        gap:15px
    }
    .offrings{
        width: 100% ;
        margin: auto;
    }
    .offringBox{
        height: 180px;
        margin-left:0;
    }
}