.downloadForm{
    margin-top: 170px;
    overflow: hidden;
}
.downloadForm1{
    background: rgb(249, 249, 249);
}
.collapse{
    border-radius: 0px;
}
.downloadForm2_1{
    max-width: 1000px;
    display: flex;
    align-items: center;
    gap: 20px;
    border:1px solid rgb(210, 210, 210);
    padding: 8px 10px;
    background: #F1F1F1;
}
.card-body{
    border-radius: 0px;
    transition: 2000ms;
}
.card>div{ 
    border: 1px solid rgb(210, 210, 210) ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 0px;
}
 
@media(max-width:990px){
    .downloadForm{
        margin-top:50px;
    }
}