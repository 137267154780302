.login{
    margin-top:150px ;
    background-color: rgb(255, 255, 255);
}
.login1{
    max-width: 600px;
    max-height: 500px;
    margin:15% auto;
    background-color: white;
    border-radius:10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.swal-button{
    background-color:#009253;
}
.swal-button:hover{
    background-color:#087a49 !important;
}
.swal-text{
    font-weight: bold;
}
.swal2-styled.swal2-confirm{
    background-color:#009253 !important;

}