.notificationSectionMain{
    background:linear-gradient( rgb(245, 245, 248) 50%,#BB355A 50%) ;

}
.notificationSection{
    width: 85%;
    margin: auto;
    background-color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow:3px 3px 15px 0px grey;
    align-items: center;
    justify-content: space-evenly;
}
.homeNoticeUi{
    margin: 10px 0;
    display: flex; 
    flex-wrap: wrap;
    list-style: none;
    gap: 10px;
    align-items: center;
   justify-content: space-between;
    
}
.homeNoticeUiDiv{
    display: flex;
    gap: 10px;
    cursor: pointer;
    padding: 5px;
    align-items: center;
    justify-content: space-around;
}
.homeNoticeUiDiv >p{
    margin-bottom: 0;
}
.homeNoticeUiDiv>img{ 
    margin-bottom: 0px;
}

.footer1{
    width: 80%;
    margin: 50px auto;
    display: grid;
    grid-template-columns:25% 25% 25% 25%;
    text-align: left;

}
.footer1 > div >div>img{
    width: 80%;
}
.socialIcon{
   float: right;
    width: 250px;
    display: flex;
    gap: 10px;
    justify-content: space-around;
}
.footerIcons{
    font-size: 35px;
}
.footerIcons:hover{
    color: rgb(187, 53, 90);
}

 .footerUl{
    list-style: none;
    display: grid;
    text-align: left; 
    padding: 0;
 }
 .footerUl>li>p{
    margin-bottom: 5px; 
    font-size: 14px;
    font-weight: 400;
     
 }
 .footer2{
   width: 85%;
   margin: auto;
   border-top: 1px solid;
    display: grid;
    grid-template-columns: 20% 70%;
    font-size:14px;
    align-items: center;
    justify-content: left;
    padding: 20px 0;
 }
 
 .footer2-text{
    display: flex;
    align-items: center;
    justify-content: space-around;
 }
 .liHide >div{
    border: 1px solid #989898;
    height: 44px;
    background: #989898;
    margin: 0px 20px
 }
 /* --------- */
 .footer0{
    width: 85%;
    margin: auto;
    background: white;
    padding:   20px;
    border-radius: 10px;
 }
 .footer0_1{ 
    display: flex;
    /* grid-template-columns: 24% 1% 25% 1% 25% 1% 24%; */
    align-items: center; 
    justify-content: space-around;
 }
 #notificationLine{
    /* border: 1px solid gray; */
    background: gray;
    margin: 0;
    padding: 0;
    width: 1px !important;
    height: 40px;    
 }
 .footer0_1>div{
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 10px;
    z-index: 0 !important;
 }
 .footer3{
   width: 85%;
   margin: auto;
   display: grid;
   grid-template-columns: 60% 40%;
   align-items: center;
   justify-content: space-around;
   padding: 20px 0;
 }
 .footer3>div>p{
   font-size: 14px;
   text-align: left;
 }
 .scroll-to-top{
   border-radius: 50%;
   background-color:#198754 !important ;
 }
 .scroll-to-top>svg{
   fill: #ffffff;
 }
 .footer4{
   /* height: 900px; */
   /* background-image: url('../../images/footer/footerBg2.png'),url('../../images/footer/footerBg.png'); */
  /* background-image: url('../../images/footer/footerBgg.png'); */
   background-size:cover;
   background-repeat: no-repeat;
 }
 .footerIcons1{
   color: #ffffff;
 }
 .footerIcons1:hover{
   color: #c7c7c7;

 }
 #notificationLine2{
   display: block;
   background: gray;
   margin: 0;
   padding: 0;
   width: 1px !important;
   height: 40px; 
 }
 .footer2 p>a{ 
   color: black;
}
 @media (max-width:1160px){
    .footer0_1{ 
        display: grid; 
        align-items: center; 
        grid-template-columns: 49% 49%;
        justify-content: space-around; 
     }
     #notificationLine2{
      display: none;
     }
 }
 @media (max-width:800px){
    .footer1{
        width: 85%;
        margin: 50px auto;
        display: grid;
        grid-template-columns:50% 50%;
        text-align: left;
    
    }
    .footer2{
        display: grid;
        grid-template-columns:  100%;
        gap: 20px;
        font-size: 14px;
        align-items: center;
        justify-content: left;
        text-align: left;
     }
     .footer3{ 
      margin: auto;
      display: grid;
      grid-template-columns: 100%;
      align-items: center;
      justify-content: left;
    }
 }

 @media (max-width:650px){
   .footer0{
      padding:   10px;
   }
    .footer0_1{ 
        display: grid; 
        grid-template-columns: 100%;
        align-items: center; 

     }
     #notificationLine{
        display: none;
     }
     .footer0_1>div{
        display: grid;
        grid-template-columns: 25% 75%;
        align-items: center;
        justify-content: left;
        background: white;
        padding: 10px;
        text-align: left;
     }
    .footer1{
        width: 85%;
        margin: 50px auto;
        display: grid;
        grid-template-columns:100%;
        text-align: left;
        gap: 0px;
    
    }
   
    .liHide{
        display: none;
    }
    .homeNoticeUi{
        margin: 10px 0;
        display: flex; 
        flex-wrap: wrap;
        list-style: none;
        gap: 10px;
        align-items: center;
       justify-content: space-between;
        
    }
 }

 