 
.scroller {
    height: 30px;
    line-height: 1.2em;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  .scroller> span {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    animation: slide 15s infinite;
    font-weight: bold; 
  }
  @keyframes slide {
    0% {
      top: 0;
    }
    33% {
      top: -38px;
    }
    66% {
      top: -83px;
    }
     
  }
  .imready{
  cursor: pointer;
  animation-name: premio;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.imready:hover{
  animation-name: premio;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  }


@keyframes premio{
  0% {
    background-color: #006600;
  }
  50% {
    background-color: #19d219;
    transform: scaleY(1.1) scaleX(0.95);
    border-radius: 4px;
  }
  100% {
    background-color: #006600
} 
}
.navbarMain{ 
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1; 
    height: auto;   
    transition: 300ms; 
}
.navbarMain--hidden{
    transform: translateY(-260px);
    transition: 2000ms; 
}
.nav_head{
    height: auto;
    position: relative;
    display: grid;
    grid-template-columns: 40% 60% ; 
}
.nav_head_left{
    display: flex;
    align-items: center;
}
.nav_logo_div{
    width:120px;
    padding: 10px;
}
.nav_logo_div2{
    width:60px;
    padding: 10px;
}
.nav_logo_div2 > img{
    width: 100%;
    height: 100%;
}
.nav_logo_div > img{
    width: 100%;
    height: 100%;
}
.nav_logo_text{
    position: relative;
    width: 100%;
}
/* .nav_logo_text > p{
    text-align: left;
    padding: 0;
    margin: 0;
} */
.logoText{ 
    font-size: 30px !important;
    position: absolute;
    top: 38%;
    left: 0;
    width: 100%;
    font-family:'Bauhaus 93' !important;
}
.small_text{
    margin-top: 85px;
    font-size: 10px;
    font-weight: bold;
    /* text-decoration: underline; */
}
.nav_head_right{
    width: 100%;
}
.nav_head_right_top{
    border-radius: 0 0 0 100px;
    display: flex;
    align-items:center;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    background-color:rgb(187, 53, 90) ;
}
.nav_head_top_ul{
    margin: 5px 0 0 0 ;
    display: flex; 
    text-decoration: none;
    list-style: none;
    gap: 8px;
    color: white; 
} 
.nav_head_top_ul>p{
font-size: 16px;
padding: 0;
margin: 0;
}
.nav_head_right_bottom{
    display: flex;
    align-items:center;
    justify-content: space-around;
}
.nav_head_right_bottom >div{
    margin-top: 8px;
}
.nav_head_right_bottom_1{
    display: flex;
    align-items: center;
    justify-content: space-around;
} 
.carousel-root>button{
z-index: 0;
}
.inputHead{
 border: 1px solid rgb(228, 228, 228);
 border-radius: 15px;
 background-color: rgb(245, 245, 245);
margin-bottom: 8px;
}
.inputHead>input{
    /* width: 80%; */
    background-color: rgb(245, 245, 245); 
    border-radius: 15px;
    border: hidden;
    outline-style: none;
    padding: 5px 10px;
}
.smallNave{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.inputHeadSmall{
    display: none;
    align-items: center;
    margin: 0;
    border-radius: 15px;
    color: #ffffff;
   }
   .smallScreenHead{
    display: flex;
    align-items: center;
   }
   .smallScreenHead>span:hover{
    cursor: pointer;
   }
.inputHeadSmall>input{
    width: 150px;
    background-color: rgb(245, 245, 245); 
    border-radius: 15px;
    border: none;
    padding: 5px 10px;
   } 

.nav_logo_divSmall{
    display: none;
}
.nav_logo_divSmall2{
    display: none;
} 

.searchDropdown{
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid gray; 
}
.searchDropdown:empty{
    border: none;
}
.dropdown-row{
    cursor: pointer;
}
.mSearch{
    display: none;
}
@media(max-width:1330px){
    .logoText{
        font-size: 30px !important;
    }
}
@media(max-width:1230px){
    .logoText{
        font-size: 28px !important;
    }
}
@media(max-width:1160px){
    .nav_head_right_bottom_1{
        display: none;
    }
}
@media(max-width:990px){ 
    #nav_head{
        display: none;
    }
    .mobileTogal{
        display: block; 
    }
    .navbarMain--hidden{
        transform: translateY(-220px);
    }
    .nav_head{
        position: relative;
        display: grid;
        grid-template-columns: 40% 60% ; 
    }
    .nav_head_top_ul_hide{
        display: none;
    } 
    .nav_logo_divSmall{
        display:flex;
        width: 45px;
        align-items: center;
    }
    .nav_logo_divSmall2{
        display:flex; 
        align-items: center;
        color: #fdf0e8;
    }
    .nav_logo_divSmall>img{
        width: 100%;
        height: 100%;
    }
    .inputHeadSmall{
        display: block;
    } 
    .mSearch{
        display: block;
    }
    .navbar-Menu{
        display: none;
    }
    .mobileTogal{
        display: block !important; 
    }
    .offcanvas.offcanvas-end{
        max-width: 80%;
    }
    .nav-item{ 
        /* margin-top: 10px; */
        padding: 15px;
        color: #ffffff; 
    }
   
    .nav-item>a:hover{
        color: #ffffff;
    }
    li{
        padding: 10px;
        font-size: 20px;
        font-weight: 700;  
        
    }
    li>.collapse{
        transition: 1500ms;
    }
    #mobileNavUl{
        padding: 6px;
        font-size: 17px;
        font-weight: 500;  
        color: rgb(248, 226, 197)!important;
    }
    .sub-menu>li{
        padding: 5px !important;
        font-size: 16px !important;
        font-weight: 400 !important; 
        color:rgb(204, 200, 200);
        
    }
    #smallNav li{
        border-bottom: 1px solid #ffffff;
    }
  
}
.language{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
@media(max-width:800px){
     .navbarMain--hidden{
        transform: translateY(-238px);
    }
    .nav_logo_text{
        display: none;
    }
    .nav_head{
        position: relative;
        display: grid;
        grid-template-columns: 20% 80% ; 
    } 
    
    .nav_head_right_top{
    border-radius: 0 0 0 100px;
    display: flex;
    align-items:center; 
    margin: 0;
    padding: 0;
    background-color:rgb(187, 53, 90) ;
}
    .nav_head_right_bottom{
        display: grid;
        align-items:center;
        justify-content: space-around;
    }
    .nav_head_right_bottom >div{
        margin-top: 0px;
    }

}
 
 

/* ------------------------------ */
.navbar{
    position: relative;
    padding: 0;
}
/* .collapseDropdown{
    position: absolute;
    width: 100%;
    height:120vh;
    top: 42px;
    left: 0;
    border-radius:0px ;
    display: none;
    padding: 0px 0px;
    background:rgba(0,0,0,.5);
}  */
.collapseDropdown{
    position: absolute;
    width: 70%;
    height: fit-content;
    top: 42px;
    left: 15%;
    border-radius:0px ;
    /* display: none; */
    padding: 0px 0px;
    background:#f9f9f9;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; 
    border-radius: 0px 0px 8px 8px;
    cursor: pointer;
} 

/* -----------------down-------------- */
.collapseDropdown::backdrop{
    background: black;
    opacity: 0;
    transition: opacity 500ms;
}
 
.collapseDropdown{
    animation: fade-in 1000ms forwards;
} 
@keyframes fade-in{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes fade-out{
    100%{
        opacity: 0;;
    }
    0%{
        opacity: 1;
    }   
}
@keyframes slide-up{
    0%{
        transform: translateY(-3%);
    }
    100%{
        transform: translateY(0%);
    }
}

 
.mobileNav{
    animation: fade-in 1000ms forwards;
} 
@keyframes fade-in{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes fade-out{
    100%{
        opacity: 0;;
    }
    0%{
        opacity: 1;
    }   
}
@keyframes slide-up{
    0%{
        transform: translateY(-3%);
    }
    100%{
        transform: translateY(0%);
    }
}
/* ------------up------------ */
a{
    text-decoration: none;
    color: #ffffff;
}
a:hover{
    color: #ffffff;
}
li>a{
    text-decoration: none;
    color: black;
}
li>a:hover{
    color: black;
}
/* .open:hover + .collapseDropdown{
    display: block;
} */


/* .collapseDropdown:hover{
    display: block; 
} */
 /* --------- */
/* .collapseShow{
    width: 60%;
    margin: auto;
    background: #f9f9f9;
    height: auto;
} */
 
.collepsUl{
    list-style: none;
    width: 30%; 
    min-height: 50vh;
    background: white;
    padding: 0;
    margin-bottom: unset;
    border-right: .5px solid #ede9e9;
    border-radius: 0px 0px 0px 8px;
}
.collepsUl>li{
    display: flex;
    justify-content: space-between;
    padding: 18px ;
    width: 100%;    
    text-align: left;
}
.collepsUl>li:hover{
    background: #fdf0e8;
    
}

.collepsUl>li:hover .menuRight{
    color: #fd7e14;
}
 
.submenuDrop:hover +.submenuDrop-dropdown-menu{
    display: block !important;
    position: relative !important; 
} 
.submenuDrop-dropdown-menu{
    /* position: absolute;  */
    top: -50px !important;
    left: 100% !important;
    border: none;
    border-radius: 0px;
    background:transparent;
    width: 70%;
    cursor: pointer;
    /* min-height: 100%; */
    height: unset;
    display:none !important;
    position: relative !important; 
     bottom: -50px;
}
.submenuDrop-dropdown-menu:hover{
    display: block !important;
}
.submenuDrop-dropdown-menu > li{
    width:max-content ;
    padding:2px 0px; 
}
.submenuDrop-dropdown-menu > li >a:hover{
    background: transparent;
    color: #fd7e14;
}
 
.navbar-Menu{
    margin: auto;
}
.mobileTogal{
    display: none;  
}   
 
 




/* --------------------------- */
